import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';  // Adjust the path if necessary
import { IonicModule } from '@ionic/angular';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [IonicModule, RouterModule]
})
export class NavbarComponent {
  userRole: string | null = null;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.authService.getRole().subscribe((role) => {
      this.userRole = role;
    });
  }

  handleAuthAction() {
    if (this.userRole) {
      // Log out and navigate to the login page
      this.authService.logout();
      this.router.navigate(['/login']);
    } else {
      // Navigate to the login page
      this.router.navigate(['/login']);
    }
  }
  handleRegister() {
    this.router.navigate(['/register-user']);
  }
}
