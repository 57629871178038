<ion-header>
  <ion-toolbar>
    <ion-title>Orpetron</ion-title>
    <ion-buttons slot="end">
      <ion-button routerLink="/home">Home</ion-button>
      <ion-button routerLink="/profile">Profile</ion-button>
      <ion-button (click)="handleAuthAction()">
        {{ userRole ? 'Logout' : 'Login' }}
      </ion-button>
      <ion-button (click)="handleRegister()">Register</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
