import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authUrl = 'https://headless.wordpress.orpetron.com/wp-json/jwt-auth/v1/token';  // JWT authentication URL
  private graphqlUrl = 'https://headless.wordpress.orpetron.com/graphql';  // WordPress GraphQL URL
  private registerUrl = 'https://headless.wordpress.orpetron.com/wp-json/custom-api/v1/register'; // Custom user registration URL

  // BehaviorSubject to track user role
  private userRoleSubject = new BehaviorSubject<string | null>(null);
  public userRole$ = this.userRoleSubject.asObservable();  // Observable for components to subscribe to

  constructor(private http: HttpClient) {
    const storedRole = localStorage.getItem('user_role');
    if (storedRole) {
      this.userRoleSubject.next(storedRole);  // Set initial value from localStorage if available
    }
  }
  /**
   * Register method to create a new user in WordPress
   */
  register(userData: any): Observable<any> {
    return this.http.post(this.registerUrl, userData).pipe(
      map((response: any) => {
        return response; // Return the response after successful registration
      }),
      catchError((error) => {
        return throwError(error); // Re-throw the error so it can be handled
      })
    );
  }
  /**
   * Login method to authenticate a user and obtain JWT token
   */
  login(username: string, password: string): Observable<any> {
    const body = { username, password };

    return this.http.post(this.authUrl, body).pipe(
      map((response: any) => {
        if (response.token) {
          localStorage.setItem('access_token', response.token);  // Store JWT token in localStorage
          return response.token;
        } else {
          throw new Error('Login failed, no token returned');
        }
      }),
      catchError((error) => {
        return throwError(error);  // Re-throw the error so it can be handled
      })
    );
  }

  /**
   * Fetch user role using GraphQL and JWT token
   */
  getUserRole(): Observable<any> {
    const token = localStorage.getItem('access_token');

    if (!token) {
      return throwError('No access token found');
    }

    const query = {
      query: `{
        viewer {
          roles {
            nodes {
              name
            }
          }
        }
      }`
    };

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    });

    return this.http.post(this.graphqlUrl, query, { headers }).pipe(
      map((response: any) => {
        if (response.data && response.data.viewer && response.data.viewer.roles) {
          const roles = response.data.viewer.roles.nodes.map((role: any) => role.name);
          const role = roles[0];  // Assuming first role is primary role
          localStorage.setItem('user_role', role);  // Store the role in localStorage
          this.userRoleSubject.next(role);  // Update BehaviorSubject
          return role;
        } else {
          throw new Error('No roles found in GraphQL response');
        }
      }),
      catchError((error) => {
        return throwError(error);  // Re-throw the error so it can be handled
      })
    );
  }

  /**
   * Logout method to remove JWT token and user role
   */
  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_role');
    this.userRoleSubject.next(null);  // Reset role to null on logout
  }

  /**
   * Observable to watch for role changes
   */
  getRole(): Observable<string | null> {
    return this.userRole$;
  }
}
